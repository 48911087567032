module.exports = {
  defaultTitle: "Matthew Cullum - Frontend Engineer",
  logo: "https://gatsby-theme-portfolio.smakosh.com/favicon/favicon-512.png",
  author: "Matthew Cullum",
  url: "https://gatsby-theme-portfolio.smakosh.com",
  legalName: "Matthew Cullum",
  defaultDescription: "I’m Matthew and I’m a frontend engineer specializing in Angular.",
  socialLinks: {
    twitter: "https://twitter.com/matthewrcullum",
    github: "https://github.com/cconcise",
    linkedin: "https://www.linkedin.com/in/thematthewcullum/",
    // instagram: "https://www.instagram.com/thematthewcullum/",
    // youtube: "https://www.youtube.com/user/smakoshthegamer",
    // google: "https://plus.google.com/u/0/+IsmailSmakoshGhallou",
    // telegram: "https://t.me/smakosh",
    // stackOverflow: "https://stackoverflow.com/users/story/7396786"
  },
  googleAnalyticsID: "UA-88875900-7",
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",
  social: {
    facebook: "appId",
    twitter: "@smakosh"
  },
  address: {
    city: "Sacramento",
    region: "California",
    country: "US",
    zipCode: ""
  },
  contact: {
    email: "matthewrcullum@gmail.com",
    phone: ""
  },
  foundingDate: "2021",
  recaptcha_key: "6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN"
};
